<template>
  <section class="invoice">
    <div class="page-header">
      <h3 class="page-title">
        Invoice
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">E-commerce</a></li>
          <li class="breadcrumb-item active" aria-current="page">Invoice</li>
        </ol>
      </nav>
    </div>
    <div class="row" v-if="invoiceDetails.content">
      <div class="col-lg-12">
        <div class="card px-2">
          <div class="card-body">
            <div class="container-fluid">
              <h3 class="text-right my-5">Facture&nbsp;&nbsp;{{invoiceDetails.ulid}}</h3>
              <hr> </div>
            <div class="container-fluid d-flex justify-content-between">
              <div class="col-lg-3 pl-0">
                <p class="mt-5 mb-2">
                  <b>{{invoiceDetails.content.seller.name}}</b>
                </p>
                <p>{{invoiceDetails.content.seller.tel}},
                  <br>Minare SK,
                  <br>Canada, K1A 0G9.</p>
              </div>
              <div class="col-lg-3 pr-0">
                <p class="mt-5 mb-2 text-right">
                  <b>Facturé à</b>
                </p>
                <p class="text-right">{{invoiceDetails.content.buyer.name}},
                  <br> {{invoiceDetails.content.buyer.address}},
                  <br> Canada, K1A 0G9.</p>
              </div>
            </div>
            <div class="container-fluid d-flex justify-content-between">
              <div class="col-lg-3 pl-0">
                <p class="mb-0 mt-5">Date de commande : {{invoiceDetails.content.dateorder}}</p>
                <p>Date de livraison : {{invoiceDetails.content.datedelivery}}</p>
              </div>
            </div>
            <div class="container-fluid mt-5 d-flex justify-content-center w-100">
              <div class="table-responsive w-100">
                <table class="table">
                  <thead>
                    <tr class="bg-dark text-white">
                      <th>#</th>
                      <th>Description</th>
                      <th class="text-right">Quantity</th>
                      <th class="text-right">Unit cost</th>
                      <th class="text-right">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="text-right">
                      <td class="text-left">1</td>
                      <td class="text-left">Brochure Design</td>
                      <td>2</td>
                      <td>$20</td>
                      <td>$40</td>
                    </tr>
                    <tr class="text-right">
                      <td class="text-left">2</td>
                      <td class="text-left">Web Design Packages(Template) - Basic</td>
                      <td>05</td>
                      <td>$25</td>
                      <td>$125</td>
                    </tr>
                    <tr class="text-right">
                      <td class="text-left">3</td>
                      <td class="text-left">Print Ad - Basic - Color</td>
                      <td>08</td>
                      <td>$500</td>
                      <td>$4000</td>
                    </tr>
                    <tr class="text-right">
                      <td class="text-left">4</td>
                      <td class="text-left">Down Coat</td>
                      <td>1</td>
                      <td>$5</td>
                      <td>$5</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="container-fluid mt-5 w-100">
              <p class="text-right mb-2">Sub - Total amount: $12,348</p>
              <p class="text-right">vat (10%) : $138</p>
              <h4 class="text-right mb-5">Total : $13,986</h4>
              <hr> </div>
            <div class="container-fluid w-100">
              <a href="javascript:void(0);" class="btn btn-primary float-right mt-4 ml-2">
                <i class="mdi mdi-printer mr-1"></i>Exporter PDF</a>
              <a href="javascript:void(0);" class="btn btn-success float-right mt-4">
                <i class="mdi mdi-telegram mr-1"></i>Envoyer E-mail</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
const axios= require ('axios').default;
export default {
  props: [
  ],
  name: 'invoice',
  data () {
    return {
      invoiceDetails: {
        slugname:"essai"
      },
      detailsFacture:"",
      ulid:""
    }
  },
  methods:{
    // downloadPdf() {
    //   const doc = new jsPDF();
    //   doc.text("Hello world!", 10, 10);
    //   doc.save("a4.pdf");
    // }
  },
  mounted() {
    // const detailsFacture = this.$route.params.detailsFacture
    // console.log('response_params_detailsFacture', detailsFacture)
    // this.detailsFacture= detailsFacture
  },
  async created () {
    // const detailsFacture = this.$route.params.detailsFacture
    // console.log('response_params_detailsFacture', detailsFacture)
    // this.detailsFacture = detailsFacture
    this.ulid=this.$route.params.ulid
    axios.get(`bills/${this.ulid}`)
    .then(res => {
      console.log ('resInvoice', res)
      this.invoiceDetails= res.data.result
    })
    .catch(err=> console.log('error', err))
  }
}
</script>